exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-gdzie-kupic-tsx": () => import("./../../../src/pages/gdzie-kupic.tsx" /* webpackChunkName: "component---src-pages-gdzie-kupic-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-dziekujemy-tsx": () => import("./../../../src/pages/kontakt/dziekujemy.tsx" /* webpackChunkName: "component---src-pages-kontakt-dziekujemy-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-monitoring-tsx": () => import("./../../../src/pages/monitoring.tsx" /* webpackChunkName: "component---src-pages-monitoring-tsx" */),
  "component---src-pages-urzadzenie-aplikacja-mobilna-tsx": () => import("./../../../src/pages/urzadzenie/aplikacja-mobilna.tsx" /* webpackChunkName: "component---src-pages-urzadzenie-aplikacja-mobilna-tsx" */),
  "component---src-pages-urzadzenie-funkcjonalnosci-tsx": () => import("./../../../src/pages/urzadzenie/funkcjonalnosci.tsx" /* webpackChunkName: "component---src-pages-urzadzenie-funkcjonalnosci-tsx" */),
  "component---src-pages-urzadzenie-jak-to-dziala-tsx": () => import("./../../../src/pages/urzadzenie/jak-to-dziala.tsx" /* webpackChunkName: "component---src-pages-urzadzenie-jak-to-dziala-tsx" */),
  "component---src-pages-urzadzenie-tsx": () => import("./../../../src/pages/urzadzenie.tsx" /* webpackChunkName: "component---src-pages-urzadzenie-tsx" */),
  "component---src-pages-zamow-tsx": () => import("./../../../src/pages/zamow.tsx" /* webpackChunkName: "component---src-pages-zamow-tsx" */),
  "component---src-pages-zastosowania-tsx": () => import("./../../../src/pages/zastosowania.tsx" /* webpackChunkName: "component---src-pages-zastosowania-tsx" */),
  "component---src-pages-zespol-tsx": () => import("./../../../src/pages/zespol.tsx" /* webpackChunkName: "component---src-pages-zespol-tsx" */),
  "component---src-templates-applications-tsx": () => import("./../../../src/templates/applications.tsx" /* webpackChunkName: "component---src-templates-applications-tsx" */),
  "component---src-templates-blog-article-tsx": () => import("./../../../src/templates/blog-article.tsx" /* webpackChunkName: "component---src-templates-blog-article-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-distribution-item-tsx": () => import("./../../../src/templates/distribution-item.tsx" /* webpackChunkName: "component---src-templates-distribution-item-tsx" */),
  "component---src-templates-monitoring-tsx": () => import("./../../../src/templates/monitoring.tsx" /* webpackChunkName: "component---src-templates-monitoring-tsx" */),
  "component---src-templates-term-tsx": () => import("./../../../src/templates/term.tsx" /* webpackChunkName: "component---src-templates-term-tsx" */)
}

