module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://mytracko.pl/"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"cookieName":"gatsby-gdpr-google-analytics","anonymize":true,"allowAdFeatures":false},"googleTagManager":{"trackingId":"GTM-NKCC55X","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"facebookPixel":{"cookieName":"gatsby-gdpr-facebook-pixel"},"tikTokPixel":{"cookieName":"gatsby-gdpr-tiktok-pixel"},"hotjar":{"cookieName":"gatsby-gdpr-hotjar"},"linkedin":{"cookieName":"gatsby-gdpr-linked-in"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9cd70abaaa7d680d4be388089ab26bd3"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"autoGenHomeLabel":"Strona główna","crumbLabelUpdates":[{"pathname":"/monitoring","crumbLabel":"Monitoring pojazdów"},{"pathname":"/urzadzenie","crumbLabel":"Urządzenie"},{"pathname":"/zastosowania","crumbLabel":"Zastosowania"},{"pathname":"/blog","crumbLabel":"Blog o monitoringu GPS pojazdów – MYTRACKO"}],"useAutoGen":true,"exclude":["**/404/**","**/dziekujemy/**"]},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
